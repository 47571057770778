import React, { useContext, useEffect } from "react";
import Layout from "../../components/layout/Layout";
import MyContext from "../../context/MyContext";
import "../../styles/optionchain.css";
import Navbar from "../../components/navbar/Navbar";
import TVCharts from "../../components/TradingViewChart/TVcharts";

function Optionchain() {
  const {
    setWatchListShown,
    pageName,
    setPageName,
    setIsChildAccount,
    setChartToken,
  } = useContext(MyContext);

  useEffect(() => {
    setPageName("optionchain");
    setChartToken("26000");
  }, []);

  return (
    <Layout>
      <div style={{ marginLeft: 10, paddingBottom: 10 }}>
        <TVCharts />
      </div>
    </Layout>
  );
}

export default Optionchain;
