import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { LockProvider } from "./context/LockContext";
import ScreenLock from "./pages/home/ScreenLock";
import Charts from "./pages/home/Charts";
import Funds from "./pages/home/Funds";
import Holdings from "./pages/home/Holdings";
import Orders from "./pages/home/Orders";
import Positions from "./pages/home/Positions";
import Optionchain from "./pages/home/Optionchain";
import BrokerIntegration from "./pages/home/BrokerIntegration";
import ScriptSetting from "./pages/home/ScriptSetting";
import Dashboard from "./pages/home/Dashboard";
import AddChildAccount from "./pages/home/AddChildAccount";
import ProtectedRoute from "./pages/home/ProtectedRoutes";
import Signin from "./pages/signin/Signin";
import Signup from "./pages/signup/Signup";
import OtpConfirmation from "./pages/Otp/OtpConfirmation";
import TradeHistory from "./pages/home/TradeHistory";
import NotFound from "./pages/home/NotFound";
import MyState from "./context/MyState";
// import TradingViewChart from "./components/TradingViewChart/index"
import { isAuthenticated } from "./pages/home/Auth";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <MyState>
      <LockProvider>
        <ScreenLock />
        <Router>
          <Routes>
            <Route
              path="/"
              element={
                <Navigate
                  to={isAuthenticated() ? "/dashboard" : "/login"}
                  replace
                />
              }
            />
            <Route path="/signup" element={<Signup />} />
            <Route path="/login" element={<Signin />} />
            <Route path="/otpconfirmation" element={<OtpConfirmation />} />
            <Route element={<ProtectedRoute />}>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/addChildAccount" element={<AddChildAccount />} />
              <Route path="/charts" element={<Charts />} />
              <Route path="/orders" element={<Orders />} />
              <Route path="/funds" element={<Funds />} />
              <Route path="/holding" element={<Holdings />} />
              <Route path="/position" element={<Positions />} />
              <Route path="/optionchain" element={<Optionchain />} />
              <Route path="/scriptsetting" element={<ScriptSetting />} />
              <Route path="/tradehistory" element={<TradeHistory />} />
              <Route
                path="/brokerintegration"
                element={<BrokerIntegration />}
              />
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
          <ToastContainer />
        </Router>
      </LockProvider>
    </MyState>
  );
}

export default App;
