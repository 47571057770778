import * as FileSaver from "file-saver";
import XLXS from "sheetjs-style";

const exportToSpreadSheet = async (list,fileName) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8";
  const fileExtension = ".xlsx";
  const exportData = list;

  let newxExportData = exportData.map((item, index) => {
    delete item.isAddOrExitBtnOpen;
    delete item.isHover;
    return item;
  });

  const pos_header = Object.keys(newxExportData[0]);

  //convert user data worksheet
  const wos = XLXS.utils.json_to_sheet(newxExportData);

  const pos_header_style = { font: { bold: true } };

  pos_header.forEach((key, colIndex) => {
    wos[XLXS.utils.encode_col(colIndex) + "1"].s = pos_header_style;
  });

  //create workbook
  const wb = { Sheets: { data: wos }, SheetNames: ["data"] };

  //convert workbook to array buffer
  const excelBuffer = await XLXS.write(wb, {
    bookType: "xlsx",
    type: "array",
  });

  //create blob and save the file
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
};

export { exportToSpreadSheet };
