// ScreenLock.js
import React, { useState } from "react";
import { useLock } from "../../context/LockContext";
import stoxview from "../../assets/icons/stoxview.png";
import { CiUnlock } from "react-icons/ci";

function ScreenLock() {
  const { isLocked, setIsLocked } = useLock();
  const [password, setPassword] = useState("");
  
  //   const correctPassword = "1234";

  //   const handleUnlock = () => {
  //     if (password === correctPassword) {
  //       setIsLocked(false);
  //       setPassword("");
  //     } else {
  //       alert("Incorrect password");
  //     }
  //   };

  if (!isLocked) return null;

  // const signin = async (e) => {
  //   e.preventDefault()
  //   setLoading(true);
  //   const body = {
  //     mobile_number: mobile,
  //   };

  //   await fetch("https://app.stoxviews.com/api/login", {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       // 'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
  //     },
  //     body: JSON.stringify(body),
  //   })
  //     .then((res) => res.json())
  //     .then((value) => {
  //       // console.log("sign in :", value);
  //       if (value.status === true) {
  //         setLoading(false);
  //         toast.success(`${value.message}`, {
  //           position: "top-right",
  //           autoClose: 2000,
  //           hideProgressBar: true,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //           theme: "colored",
  //         });

  //         navigate("/otpconfirmation", { state: { mobile: mobile, mode: 0 } });

  //         // setAuthToken(value.sessionID);
  //         // dispatch(setSessionID(value.sessionID));
  //         // localStorage.setItem("email", JSON.stringify(email));

  //         // getDashboardCall(value.sessionID);
  //         // getPosition(value.sessionID);
  //         // callGetOptionChain(value.sessionID);
  //         //navigate("/dashboard", { replace: true });
  //       } else {
  //         setLoading(false);
  //         toast.error(`${value.message}`, {
  //           position: "top-right",
  //           autoClose: 5000,
  //           hideProgressBar: true,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //           theme: "colored",
  //         });
  //       }
  //     })
  //     .catch((err) => {
  //       setLoading(false);
  //       toast.error(`${err}`, {
  //         position: "top-right",
  //         autoClose: 5000,
  //         hideProgressBar: true,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //         theme: "colored",
  //       });
  //       console.log(err);
  //     });
  // };

  const signin = async (e) => {
    e.preventDefault();
    const email = JSON.parse(localStorage.getItem("email"));

    const body = {
      email: email,
      password: password,
    };

    await fetch("https://app.stoxviews.com/api/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((value) => {
        // console.log("unlock screen :", value);
        if (value.status === "Success") {
          setIsLocked(false);
          setPassword("");
        } else {
          alert(value.message);
          setIsLocked(true);
        }
      })
      .catch((err) => {
        setIsLocked(true);
        console.log(err);
      });
  };

  return (
    <div style={styles.lockScreen}>
      <img
        src={stoxview}
        alt="logo icon"
        className="w-[129px] h-[30px] mb-[48px]"
      />
      <h2>Enter Password to Unlock</h2>
      <input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        className="mt-1"
        style={styles.input}
      />
      <button
        // onClick={handleUnlock}
        onClick={signin}
        className="flex items-center justify-evenly"
        style={styles.button}
      >
        <CiUnlock className="mr-1" />
        Unlock
      </button>
    </div>
  );
}

const styles = {
  lockScreen: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.85)",
    color: "white",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1000,
  },
  input: {
    padding: "10px",
    fontSize: "16px",
    marginBottom: "10px",
    color: "#000000",
  },
  button: {
    padding: "10px 20px",
    fontSize: "16px",
    cursor: "pointer",
  },
};

export default ScreenLock;
