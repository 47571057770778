import React, { useEffect, useContext, useState } from "react";
import Layout from "../../components/layout/Layout";
import MyContext from "../../context/MyContext";
import DownloadIcon from "../../assets/icons/download.png";
import selectIcon from "../../assets/icons/select.png";
import Loader from "../../components/loader/Loader";
import "../../App.css";
import BuyAndSellModal from "../../components/modals/BuyAndSellModal";
import { SlReload } from "react-icons/sl";
import * as FileSaver from "file-saver";
import XLXS from "sheetjs-style";

function Positions() {
  const {
    setWatchListShown,
    setPageName,
    loading,
    setLoading,
    positions,
    setPositions,
    getPosition,
    setBopen,
    setBuyAndSellAction,
    isToggled,
    setIsToggled,
    setBuyAndSellTradingSymbol,
    setBuyAndSellExchange,
    setChartToken,
    setBuyAndSellExchangeToken,
    qty,
    setQty,
    setBuyAndSellModalLivePrice,
    isDemoPage,
    isMasterOrChildPosition,
    setLotSize,
  } = useContext(MyContext);

  const [positionStatusCount, setPositionStatusCount] = useState([
    {
      status: "STOCKS",
      value: 0,
      isSelected: false,
    },
    {
      status: "FUT",
      value: 0,
      isSelected: false,
    },
    {
      status: "OPT",
      value: 0,
      isSelected: false,
    },
    {
      status: "MCX",
      value: 0,
      isSelected: false,
    },
    {
      status: "CDS",
      value: 0,
      isSelected: false,
    },
  ]);

  const [isAllSelected, setIsAllSelected] = useState(true);
  const [isToggledTodayOverAll, setIsToggledTodayOverAll] = useState(false);
  const [totalPNL, setTotalPNL] = useState(0.0);
  const [totalLTP, setTotalLTP] = useState(0.0);
  const [demoPositionToday, setDemoPositionToday] = useState([]);
  const [demoPositionOverall, setDemoPositionOverall] = useState([]);
  const [demoFilterOption, setDemoFilterOption] = useState([]);

  const handleToggle = () => {
    setIsToggledTodayOverAll(!isToggledTodayOverAll);
    //filterPositions()
  };

  const statusClasses = {
    COMPLETED: { bgcolor: "#D4EDDA", textColor: "#155724" },
    REJECTED: { bgcolor: "#F8D7DA", textColor: "#721C24" },
    PENDING: { bgcolor: "#B6E0FF", textColor: "#013052" },
  };

  const statusBgColor = (status) => {
    let bg = "";
    switch (status) {
      case "CNC":
        bg = statusClasses.COMPLETED.bgcolor;
        break;

      case "NRML":
        bg = statusClasses.REJECTED.bgcolor;
        break;

      case "MIS":
        bg = statusClasses.PENDING.bgcolor;
        break;
    }
    return bg;
  };

  const statusTextColor = (status) => {
    let color = "";
    switch (status) {
      case "NSE":
        color = statusClasses.COMPLETED.textColor;
        break;

      case "NRML":
        color = statusClasses.REJECTED.textColor;
        break;

      case "MIS":
        color = statusClasses.PENDING.textColor;
        break;
    }
    return color;
  };

  useEffect(() => {
    let newPNL = 0;
    // let newLtp = 0;

    demoFilterOption?.forEach((position) => {
      const Ltp = parseFloat(position?.last_traded_price);
      const BuyAT = position?.buy_qty * position?.buy_avg;
      const SELLAT = position?.sell_qty * position?.sell_avg;
      const old_profit = BuyAT - SELLAT;
      const NbuyAt = position?.buy_qty * Ltp;
      const NSellAt = position?.sell_qty * Ltp;
      const New_Profit = NbuyAt - NSellAt;
      const PNL =
        (New_Profit - old_profit) * parseInt(position.instrument__lot_size);
      newPNL = newPNL + PNL;
      // newLtp = newLtp + Ltp;
    });

    setTotalPNL(newPNL);

    setPageName("position");
  }, [demoFilterOption, isToggledTodayOverAll, positionStatusCount, positions]);

  useEffect(() => {
   // isDemoPage && getPosition();
  }, []);

  useEffect(() => {
    if (positions.length !== 0) {
      setDemoPositionOverall(positions);
      const td = positions.filter((item) => isToday(item.odate));

      setDemoPositionToday(td);
    }
  }, [positions]);

  useEffect(() => {
    filterPositions();
  }, [positionStatusCount]);

  useEffect(() => {
    updatePositionStatusCount(
      isToggledTodayOverAll ? demoPositionOverall : demoPositionToday
    );
  }, [isToggledTodayOverAll, positions]);

  const updatePositionStatusCount = (table) => {
    const updatedCount = positionStatusCount.map((statusObj) => {
      const count = table.filter((item) => {
        if (
          statusObj.status === "STOCKS" &&
          item.instrument__instrument_type === "EQ" &&
          item.instrument__exchange !== "MCX"
        ) {
          return true;
        }
        if (
          statusObj.status === "OPT" &&
          ["CE", "PE"].includes(item.instrument__instrument_type) &&
          item.instrument__exchange !== "MCX"
        ) {
          return true;
        }
        if (
          statusObj.status === "FUT" &&
          item.instrument__instrument_type !== "EQ" &&
          !["CE", "PE"].includes(item.instrument__instrument_type) &&
          item.instrument__exchange !== "MCX"
        ) {
          return true;
        }
        if (statusObj.status === "MCX" && item.instrument__exchange === "MCX") {
          return true;
        }
        if (statusObj.status === "CDS" && item.instrument__exchange === "CDS") {
          return true;
        }
        return false;
      }).length;

      return { ...statusObj, value: count };
    });

    setPositionStatusCount(updatedCount);
  };
  const onMouseCallOverPositionInstrument = (index) => {
    //!isToggledTodayOverAll ? demoPositionToday : demoPositionOverall
    if (!isToggledTodayOverAll) {
      setDemoFilterOption(
        demoFilterOption?.map((it, idx) => {
          if (index === idx) {
            if (it.isAddOrExitBtnOpen) {
              it.isAddOrExitBtnOpen = false;
            } else {
              it.isAddOrExitBtnOpen = true;
            }
          } else {
            it.isAddOrExitBtnOpen = false;
          }
          return it;
        })
      );
    } else {
      setDemoFilterOption(
        demoFilterOption?.map((it, idx) => {
          if (index === idx) {
            if (it.isAddOrExitBtnOpen) {
              it.isAddOrExitBtnOpen = false;
            } else {
              it.isAddOrExitBtnOpen = true;
            }
          } else {
            it.isAddOrExitBtnOpen = false;
          }
          return it;
        })
      );
    }
    // setPositions(
    //   positions.map((it, idx) => {
    //     if (index === idx) {
    //       if (it.isAddOrExitBtnOpen) {
    //         it.isAddOrExitBtnOpen = false;
    //       } else {
    //         it.isAddOrExitBtnOpen = true;
    //       }
    //     } else {
    //       it.isAddOrExitBtnOpen = false;
    //     }
    //     return it;
    //   })
    // );
  };

  const onMouseCallOutPositionInstrument = (index) => {
    if (!isToggledTodayOverAll) {
      setDemoPositionToday(
        demoPositionToday.map((it, idx) => {
          it.isAddOrExitBtnOpen = false;
          return it;
        })
      );
    } else {
      setDemoPositionOverall(
        demoPositionOverall.map((it, idx) => {
          it.isAddOrExitBtnOpen = false;
          return it;
        })
      );
    }
    // setPositions(
    //   positions.map((it, idx) => {
    //     it.isAddOrExitBtnOpen = false;
    //     return it;
    //   })
    // );
  };

  const onOpenAddModal = (
    buy_qty,
    sell_qty,
    trading_symbol,
    exchange_token,
    instrument__exchange,
    livePrice,
    lot_size
  ) => {
    setBopen(true);
    setBuyAndSellTradingSymbol(trading_symbol);
    setBuyAndSellExchangeToken(exchange_token);
    setBuyAndSellExchange(instrument__exchange);
    setChartToken(exchange_token);
    setBuyAndSellModalLivePrice(livePrice);
    setLotSize(lot_size);
    setQty("");
    if (buy_qty > sell_qty) {
      setBuyAndSellAction("buy");
      setIsToggled(false);
    } else {
      setBuyAndSellAction("sell");
      setIsToggled(true);
    }
  };

  const onOpenExitModal = (
    buy_qty,
    sell_qty,
    trading_symbol,
    exchange_token,
    instrument__exchange,
    livePrice,
    lot_size
  ) => {
    setBopen(true);
    setBuyAndSellTradingSymbol(trading_symbol);
    setBuyAndSellExchangeToken(exchange_token);
    setBuyAndSellExchange(instrument__exchange);
    setChartToken(exchange_token);
    setBuyAndSellModalLivePrice(livePrice);
    setLotSize(lot_size);
    if (buy_qty > sell_qty) {
      setQty(buy_qty - sell_qty);
      setBuyAndSellAction("sell");
      setIsToggled(true);
    } else {
      setQty(sell_qty - buy_qty);
      setBuyAndSellAction("buy");
      setIsToggled(false);
    }
  };

  const positionType = (type) => {
    if (type === "Intraday")
      return { type: "MIS", color: "#013052", backgroundColor: "#afdafa" };
    else if (type === "Longterm")
      return { type: "CNC", color: "#7A282F", backgroundColor: "#F8D7DA" };
  };

  const orderType = (type) => {
    if (type === "Market")
      return { type: "Market", color: "#013052", backgroundColor: "#afdafa" };
    else if (type === "Limit")
      return { type: "Limit", color: "#7A282F", backgroundColor: "#F8D7DA" };
  };

  const isToday = (dateString) => {
    const inputDate = new Date(dateString);

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);

    return inputDate >= today && inputDate < tomorrow;
  };

  // function filterList(dataList, showTodayOnly) {
  //   if (showTodayOnly) {
  //     return dataList.filter((item) => isToday(item.createdAt));
  //   }
  //   return dataList;
  // }

  const filterPositions = () => {
    const selectedFilter = positionStatusCount.find(
      (statusObj) => statusObj.isSelected
    );

    if (!selectedFilter) {
      // setDemoFilterOption(
      //   !isToggledTodayOverAll ? demoPositionToday : demoPositionOverall
      // );
      let df = !isToggledTodayOverAll ? demoPositionToday : demoPositionOverall;

      setDemoFilterOption(df);
      // return !isToggledTodayOverAll
      //   ? demoPositionToday
      //   : demoPositionOverall
    } else {
      if (!isToggledTodayOverAll) {
        let df = demoPositionToday.filter((item) => {
          if (
            selectedFilter.status === "STOCKS" &&
            item.instrument__instrument_type === "EQ" &&
            item.instrument__exchange !== "MCX"
          ) {
            return true;
          }
          if (
            selectedFilter.status === "OPT" &&
            ["CE", "PE"].includes(item.instrument__instrument_type) &&
            item.instrument__exchange !== "MCX"
          ) {
            return true;
          }
          if (
            selectedFilter.status === "FUT" &&
            item.instrument__instrument_type !== "EQ" &&
            !["CE", "PE"].includes(item.instrument__instrument_type) &&
            item.instrument__exchange !== "MCX"
          ) {
            return true;
          }
          if (
            selectedFilter.status === "MCX" &&
            item.instrument__exchange === "MCX"
          ) {
            return true;
          }
          if (
            selectedFilter.status === "CDS" &&
            item.instrument__exchange === "CDS"
          ) {
            return true;
          }
          return false;
        });

        setDemoFilterOption(df);

        // return demoPositionToday
        //   .filter((item) => {
        //     if (
        //       selectedFilter.status === "STOCKS" &&
        //       item.instrument__instrument_type === "EQ" &&
        //       item.instrument__exchange !== "MCX"
        //     ) {
        //       return true;
        //     }
        //     if (
        //       selectedFilter.status === "OPT" &&
        //       ["CE", "PE"].includes(item.instrument__instrument_type) &&
        //       item.instrument__exchange !== "MCX"
        //     ) {
        //       return true;
        //     }
        //     if (
        //       selectedFilter.status === "FUT" &&
        //       item.instrument__instrument_type !== "EQ" &&
        //       !["CE", "PE"].includes(item.instrument__instrument_type) &&
        //       item.instrument__exchange !== "MCX"
        //     ) {
        //       return true;
        //     }
        //     if (
        //       selectedFilter.status === "MCX" &&
        //       item.instrument__exchange === "MCX"
        //     ) {
        //       return true;
        //     }
        //     if (
        //       selectedFilter.status === "CDS" &&
        //       item.instrument__exchange === "CDS"
        //     ) {
        //       return true;
        //     }
        //     return false;
        //   })
      } else {
        let df = demoPositionOverall.filter((item) => {
          if (
            selectedFilter.status === "STOCKS" &&
            item.instrument__instrument_type === "EQ" &&
            item.instrument__exchange !== "MCX"
          ) {
            return true;
          }
          if (
            selectedFilter.status === "OPT" &&
            ["CE", "PE"].includes(item.instrument__instrument_type) &&
            item.instrument__exchange !== "MCX"
          ) {
            return true;
          }
          if (
            selectedFilter.status === "FUT" &&
            item.instrument__instrument_type !== "EQ" &&
            !["CE", "PE"].includes(item.instrument__instrument_type) &&
            item.instrument__exchange !== "MCX"
          ) {
            return true;
          }
          if (
            selectedFilter.status === "MCX" &&
            item.instrument__exchange === "MCX"
          ) {
            return true;
          }
          if (
            selectedFilter.status === "CDS" &&
            item.instrument__exchange === "CDS"
          ) {
            return true;
          }
          return false;
        });

        setDemoFilterOption(df);

        // return demoPositionOverall
        //   .filter((item) => {
        //     if (
        //       selectedFilter.status === "STOCKS" &&
        //       item.instrument__instrument_type === "EQ" &&
        //       item.instrument__exchange !== "MCX"
        //     ) {
        //       return true;
        //     }
        //     if (
        //       selectedFilter.status === "OPT" &&
        //       ["CE", "PE"].includes(item.instrument__instrument_type) &&
        //       item.instrument__exchange !== "MCX"
        //     ) {
        //       return true;
        //     }
        //     if (
        //       selectedFilter.status === "FUT" &&
        //       item.instrument__instrument_type !== "EQ" &&
        //       !["CE", "PE"].includes(item.instrument__instrument_type) &&
        //       item.instrument__exchange !== "MCX"
        //     ) {
        //       return true;
        //     }
        //     if (
        //       selectedFilter.status === "MCX" &&
        //       item.instrument__exchange === "MCX"
        //     ) {
        //       return true;
        //     }
        //     if (
        //       selectedFilter.status === "CDS" &&
        //       item.instrument__exchange === "CDS"
        //     ) {
        //       return true;
        //     }
        //     return false;
        //   })
      }
    }

    //return !isToggledTodayOverAll ? demoPositionToday : demoPositionOverall;
  };

  const onChangePositionStatusCount = (item, index) => {
    setIsAllSelected(false);
    let newPositionOption = positionStatusCount.map((it, idx) => {
      if (index === idx) {
        it.isSelected = true;
      } else {
        it.isSelected = false;
      }
      return it;
    });
    setPositionStatusCount(newPositionOption);
  };

  const exportToSpreadSheet = async () => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8";
    const fileExtension = ".xlsx";
    const exportData = !isToggledTodayOverAll
      ? demoPositionToday
      : demoPositionOverall;

    let newxExportData = exportData.map((item, index) => {
      delete item.isAddOrExitBtnOpen;
      return item;
    });

    const pos_header = Object.keys(newxExportData[0]);

    //convert user data worksheet
    const wos = XLXS.utils.json_to_sheet(newxExportData);

    const pos_header_style = { font: { bold: true } };

    pos_header.forEach((key, colIndex) => {
      wos[XLXS.utils.encode_col(colIndex) + "1"].s = pos_header_style;
    });

    //create workbook
    const wb = { Sheets: { data: wos }, SheetNames: ["data"] };

    //convert workbook to array buffer
    const excelBuffer = await XLXS.write(wb, {
      bookType: "xlsx",
      type: "array",
    });

    //create blob and save the file
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "position_data" + fileExtension);
  };

  return (
    <Layout>
      {isDemoPage ? (
        <div className="p-4">
          {loading && <Loader />}
          <div className="flex flex-row items-center justify-between mb-4">
            <div className="flex  items-center ">
              <h1 className="text-xl font-bold mr-[36px]">Positions</h1>
              <div className="flex space-x-1">
                <button
                  onClick={() => {
                    setPositionStatusCount(
                      positionStatusCount.map((it, idx) => {
                        it.isSelected = false;

                        return it;
                      })
                    );
                    setIsAllSelected(true);
                  }}
                  className="px-3 py-1 border  bg-green-100 flex flex-row items-center"
                  style={{
                    fontSize: 14,
                    color: isAllSelected ? "#155724" : "#817E7E",
                    backgroundColor: isAllSelected ? "#D4EDDA" : "#FFFFFF",
                  }}
                >
                  ALL |{" "}
                  {!isToggledTodayOverAll
                    ? demoPositionToday.length
                    : demoPositionOverall.length}
                  {/* {demoFilterOption.length} */}
                </button>

                {positionStatusCount.map((item, index) => {
                  return (
                    <button
                      className="px-3 py-1 border"
                      key={index}
                      style={{ borderColor: "#817E7E" }}
                      onClick={() => onChangePositionStatusCount(item, index)}
                      style={{
                        fontSize: 12,
                        color: item.isSelected ? "#155724" : "#817E7E",
                        backgroundColor: item.isSelected
                          ? "#D4EDDA"
                          : "#FFFFFF",
                      }}
                    >
                      {item.status} | {item.value}
                    </button>
                  );
                })}
              </div>
            </div>

            <div className="flex flex-row items-center z-0">
              <div className="mr-[7px]">
                <span
                  style={{ fontSize: 12, fontWeight: "500", color: "#817E7E" }}
                >
                  Today
                </span>
              </div>

              <div className="flex items-center justify-center ml-[8px] mr-[8px]  bg-gray-100">
                <button
                  className={`w-14 h-6 flex items-center rounded-full p-1 duration-300 ease-in-out`}
                  style={{
                    backgroundColor: isToggledTodayOverAll
                      ? "green"
                      : "green  ",
                  }}
                  onClick={() => handleToggle()}
                >
                  <div
                    style={{ backgroundColor: "white" }}
                    className={` w-4 h-4  rounded-full shadow-md transform duration-300 ease-in-out ${
                      isToggledTodayOverAll ? "translate-x-7" : "translate-x-1"
                    }`}
                  />
                </button>
              </div>
              <div className="mr-[21px]">
                <span
                  style={{ fontSize: 12, fontWeight: "500", color: "#817E7E" }}
                >
                  Overall
                </span>
              </div>
            </div>

            <div className="mr-[16PX]">
              <button>
                <img
                  src={DownloadIcon}
                  alt="download"
                  style={{ width: 14, height: 16 }}
                  onClick={() => {
                    exportToSpreadSheet();
                  }}
                />
              </button>
              <button
                className="ml-2"
                onClick={() => {
                  getPosition();
                }}
              >
                <SlReload />
              </button>
            </div>
          </div>

          <table className="min-w-full bg-white orderTable">
            <thead className="orderTable">
              <tr>
                <th className="px-4 py-2">
                  <img
                    src={selectIcon}
                    alt="select option"
                    style={{ width: 16, height: 16 }}
                  />
                </th>
                <th className="px-4 py-2 border">Type</th>
                <th className="px-4 py-2 border">Instrument</th>
                <th className="px-4 py-2 border">Buy Qty.</th>
                <th className="px-4 py-2 border">Sell Qty.</th>
                <th className="px-4 py-2 border">Buy Avg</th>
                <th className="px-4 py-2 border">Sell Avg</th>
                <th className="px-4 py-2 border">PNL</th>
                <th className="px-4 py-2 border">LTP</th>
              </tr>
            </thead>
            <tbody>
              {demoFilterOption?.map((position, index) => {
                // instrument__exchange : "MCX"

                // if(position.instrument__exchange_token==="1023"){
                //  console.log("faderal bank : ", position);
                // }

                const Ltp = parseFloat(position?.last_traded_price);
                // console.log("demo position : ",Ltp);

                const BuyAT = position?.buy_qty * position?.buy_avg;
                const SELLAT = position?.sell_qty * position?.sell_avg;

                const old_profit = BuyAT - SELLAT;
                // console.log("old profit : ",old_profit);

                const NbuyAt = position?.buy_qty * Ltp;
                const NSellAt = position?.sell_qty * Ltp;
                const New_Profit = NbuyAt - NSellAt;
                const lotSize = parseInt(position.instrument__lot_size);

                // console.log("New_Profit : ",New_Profit);
                // const PNL =
                //   (New_Profit - old_profit) *
                //   parseInt(position.instrument__lot_size);

                const pnl = New_Profit - old_profit;
                const PNL = pnl * lotSize;

                return (
                  <tr key={index}>
                    <td className="px-4 py-2 border orderTabletd">
                      <img
                        src={selectIcon}
                        alt="select option"
                        style={{ width: 16, height: 16 }}
                      />
                    </td>
                    <td className="px-4 py-2 border orderTabletd">
                      <button
                        style={{
                          fontSize: 10,
                          paddingLeft: 4,
                          paddingRight: 4,
                          height: 20,
                          borderRadius: 3,
                          color: positionType(position.ptype).color,
                          backgroundColor: positionType(position.ptype)
                            .backgroundColor,
                        }}
                      >
                        {/* {`${position.ptype==="Intraday"?"MIS":"NRML"} `} */}
                        {positionType(position.ptype).type}
                      </button>
                    </td>

                    <td
                      className="px-4 py-2 border relative orderTabletd"
                      style={{
                        fontSize: 14,
                        color: "#363636",
                        fontWeight: "700",
                      }}
                      onMouseOver={() => {
                        onMouseCallOverPositionInstrument(index);
                      }}
                      onMouseOut={() => {
                        onMouseCallOutPositionInstrument(index);
                      }}
                    >
                      <div className="relative">
                        <div>
                          <div>{position.instrument__trading_symbol}</div>
                          <div
                            className="pt-1"
                            style={{
                              fontSize: 12,
                              color: orderType(position.otype).color,
                              // backgroundColor: orderType(position.otype)
                              //   .backgroundColor,
                            }}
                          >
                            {orderType(position.otype).type}
                          </div>
                        </div>

                        {position.isAddOrExitBtnOpen && (
                          <div className="addorexit">
                            <button
                              className="addBtn"
                              onClick={() =>
                                onOpenAddModal(
                                  position.buy_qty,
                                  position.sell_qty,
                                  position.instrument__trading_symbol,
                                  position.instrument__exchange_token,
                                  position.instrument__exchange,
                                  position.last_traded_price,
                                  position.instrument__lot_size
                                )
                              }
                            >
                              <p>Add</p>
                            </button>
                            <button
                              className="exitBtn"
                              onClick={() =>
                                onOpenExitModal(
                                  position.buy_qty,
                                  position.sell_qty,
                                  position.instrument__trading_symbol,
                                  position.instrument__exchange_token,
                                  position.instrument__exchange,
                                  position.last_traded_price,
                                  position.instrument__lot_size
                                )
                              }
                            >
                              <p>Exit</p>
                            </button>
                          </div>
                        )}
                      </div>
                    </td>
                    <td className="px-4 py-2 border orderTabletd">
                      {position.buy_qty}
                    </td>
                    <td className="px-4 py-2 border orderTabletd">
                      {position.sell_qty}
                    </td>
                    <td className="px-4 py-2 border orderTabletd">
                      {position.buy_avg}
                    </td>
                    <td className="px-4 py-2 border orderTabletd">
                      {position.sell_avg}
                    </td>
                    <td
                      className="px-4 py-2 border orderTabletd"
                      style={{
                        color: PNL >= 0 ? "#39A245" : "#DF2123",
                      }}
                    >
                      {/* {position.LTP} */}
                      {PNL.toFixed(2)}
                    </td>
                    <td
                      className="px-4 py-2 border orderTabletd"
                      style={{
                        color: Ltp >= 0 ? "#39A245" : "#DF2123",
                      }}
                    >
                      {/* {position.PL} */}
                      {position?.last_traded_price}
                    </td>
                    {/* <td
                    className="px-4 py-2 border orderTabletd"
                    // style={{
                    //   color:
                    //     parseInt(position.Netchg) > 0 ? "#39A245" : "#DF2123",
                    // }}
                  >
                    {position?.Netchg}
                  </td> */}
                  </tr>
                );
              })}
              <tr>
                <td className="px-4 py-2 border orderTabletd" colSpan="6"></td>
                <td className="px-4 py-2  orderTabletd">Total</td>
                <td className="px-4 py-2  orderTabletd">
                  {totalPNL.toFixed(2)}
                </td>
                <td className="px-4 py-2 border orderTabletd" colSpan="1"></td>
                {/* <td className="px-4 py-2  orderTabletd">
                  {isNaN(totalLTP) ? 0 : totalLTP.toFixed(2)}
                </td> */}
                {/* <td className="px-4 py-2  orderTabletd"></td> */}
              </tr>
            </tbody>
          </table>
        </div>
      ) : isMasterOrChildPosition ? (
        <div className="p-4">
          {loading && <Loader />}
          <div className="flex flex-row items-center justify-between mb-4">
            <div className="flex  items-center ">
              <h1 className="text-xl font-bold mr-[36px]">Positions</h1>
              <div className="flex space-x-1">
                <button
                  onClick={() => {
                    setPositionStatusCount(
                      positionStatusCount.map((it, idx) => {
                        it.isSelected = false;

                        return it;
                      })
                    );
                    setIsAllSelected(true);
                  }}
                  className="px-3 py-1 border  bg-green-100 flex flex-row items-center"
                  style={{
                    fontSize: 14,
                    color: isAllSelected ? "#155724" : "#817E7E",
                    backgroundColor: isAllSelected ? "#D4EDDA" : "#FFFFFF",
                  }}
                >
                  ALL | 25
                </button>

                {positionStatusCount.map((item, index) => {
                  return (
                    <button
                      className="px-3 py-1 border"
                      key={index}
                      style={{ borderColor: "#817E7E" }}
                      onClick={() => {
                        setIsAllSelected(false);
                        setPositionStatusCount(
                          positionStatusCount.map((it, idx) => {
                            if (index === idx) {
                              it.isSelected = true;
                            } else {
                              it.isSelected = false;
                            }
                            return it;
                          })
                        );
                      }}
                      style={{
                        fontSize: 12,
                        color: item.isSelected ? "#155724" : "#817E7E",
                        backgroundColor: item.isSelected
                          ? "#D4EDDA"
                          : "#FFFFFF",
                      }}
                    >
                      {item.status} | {item.value}
                    </button>
                  );
                })}
              </div>
            </div>

            <div className="flex flex-row items-center z-0">
              <div className="mr-[7px]">
                <span
                  style={{ fontSize: 12, fontWeight: "500", color: "#817E7E" }}
                >
                  Today
                </span>
              </div>

              <div className="flex items-center justify-center ml-[8px] mr-[8px]  bg-gray-100">
                <button
                  className={`w-14 h-6 flex items-center rounded-full p-1 duration-300 ease-in-out`}
                  style={{
                    backgroundColor: isToggledTodayOverAll
                      ? "green"
                      : "green  ",
                  }}
                  onClick={() => handleToggle()}
                >
                  <div
                    style={{ backgroundColor: "white" }}
                    className={` w-4 h-4  rounded-full shadow-md transform duration-300 ease-in-out ${
                      isToggledTodayOverAll ? "translate-x-7" : "translate-x-1"
                    }`}
                  />
                </button>
              </div>
              <div className="mr-[21px]">
                <span
                  style={{ fontSize: 12, fontWeight: "500", color: "#817E7E" }}
                >
                  Overall
                </span>
              </div>
            </div>

            <div className=" mr-[16PX]">
              <button>
                <img
                  src={DownloadIcon}
                  alt="download"
                  style={{ width: 14, height: 16 }}
                />
              </button>
            </div>
          </div>

          <table className="min-w-full bg-white orderTable">
            <thead className="orderTable">
              <tr>
                <th className="px-4 py-2">
                  <img
                    src={selectIcon}
                    alt="select option"
                    style={{ width: 16, height: 16 }}
                  />
                </th>
                {/* <th className="px-4 py-2 border">Type</th>
                <th className="px-4 py-2 border">Instrument</th>
                <th className="px-4 py-2 border">Buy Qty.</th>
                <th className="px-4 py-2 border">Sell Qty.</th>
                <th className="px-4 py-2 border">Buy Avg</th>
                <th className="px-4 py-2 border">Sell Avg</th>
                <th className="px-4 py-2 border">PNL</th>
                <th className="px-4 py-2 border">LTP</th> */}
                <th className="px-4 py-2 border">Type</th>
                <th className="px-4 py-2 border">Instrument</th>
                <th className="px-4 py-2 border">Buy Qty.</th>
                <th className="px-4 py-2 border">Sell Qty.</th>
                <th className="px-4 py-2 border">Buy Avg</th>
                <th className="px-4 py-2 border">Sell Avg</th>
                <th className="px-4 py-2 border">PNL</th>
                <th className="px-4 py-2 border">Ltp</th>
                {/* <th className="px-4 py-2 border">Net</th> */}

                {/* <th className="px-4 py-2 border">Action</th> */}
              </tr>
            </thead>
            <tbody>
              {filterPositions()?.map((position, index) => {
                // console.log("positions : ", position);
                const Ltp = position?.last_traded_price;
                // console.log("position last price : ", Ltp);
                const BuyAT = position?.buy_qty * position?.buy_avg;
                const SELLAT = position?.sell_qty * position?.sell_avg;
                const old_profit = BuyAT - SELLAT;
                const NbuyAt = position?.buy_qty * Ltp;
                const NSellAt = position?.sell_qty * Ltp;
                const New_Profit = NbuyAt - NSellAt;
                const PNL = New_Profit - old_profit;
                return (
                  <tr key={index}>
                    <td className="px-4 py-2 border orderTabletd">
                      <img
                        src={selectIcon}
                        alt="select option"
                        style={{ width: 16, height: 16 }}
                      />
                    </td>
                    <td className="px-4 py-2 border orderTabletd">
                      <button
                        style={{
                          fontSize: 10,
                          paddingLeft: 4,
                          paddingRight: 4,
                          height: 20,
                          borderRadius: 3,
                          color: positionType(position.ptype).color,
                          backgroundColor: positionType(position.ptype)
                            .backgroundColor,
                        }}
                      >
                        {/* {`${position.ptype}`} */}
                        {positionType(position.ptype).type}
                      </button>
                    </td>

                    <td
                      className="px-4 py-2 border relative orderTabletd"
                      style={{
                        fontSize: 14,
                        color: "#363636",
                        fontWeight: "700",
                      }}
                      onMouseOver={() => {
                        onMouseCallOverPositionInstrument(index);
                      }}
                      onMouseOut={() => {
                        onMouseCallOutPositionInstrument(index);
                      }}
                    >
                      <div className="relative">
                        <div>{position.instrument__trading_symbol}</div>

                        {position.isAddOrExitBtnOpen && (
                          <div className="addorexit">
                            <button
                              className="addBtn"
                              onClick={() =>
                                onOpenAddModal(
                                  position.buy_qty,
                                  position.sell_qty,
                                  position.instrument__trading_symbol,
                                  position.instrument__exchange_token,
                                  position.instrument__exchange,
                                  position.last_traded_price,
                                  position.instrument__lot_size
                                )
                              }
                            >
                              <p>Add</p>
                            </button>
                            <button
                              className="exitBtn"
                              onClick={() =>
                                onOpenExitModal(
                                  position.buy_qty,
                                  position.sell_qty,
                                  position.instrument__trading_symbol,
                                  position.instrument__exchange_token,
                                  position.instrument__exchange,
                                  position.last_traded_price,
                                  position.instrument__lot_size
                                )
                              }
                            >
                              <p>Exit</p>
                            </button>
                          </div>
                        )}
                      </div>
                    </td>
                    <td className="px-4 py-2 border orderTabletd">
                      {position.buy_qty}
                    </td>
                    <td className="px-4 py-2 border orderTabletd">
                      {position.sell_qty}
                    </td>
                    <td className="px-4 py-2 border orderTabletd">
                      {position.buy_avg}
                    </td>
                    <td className="px-4 py-2 border orderTabletd">
                      {position.sell_avg}
                    </td>
                    <td
                      className="px-4 py-2 border orderTabletd"
                      style={{
                        color: PNL >= 0 ? "#39A245" : "#DF2123",
                      }}
                    >
                      {/* {position.LTP} */}
                      {isNaN(PNL) ? 0 : PNL.toFixed(2)}
                    </td>
                    <td
                      className="px-4 py-2 border orderTabletd"
                      style={{
                        color: Ltp >= 0 ? "#39A245" : "#DF2123",
                      }}
                    >
                      {/* {position.PL} */}
                      {isNaN(Ltp) ? 0.0 : Ltp.toFixed(2)}
                    </td>

                    {/* <td
                    className="px-4 py-2 border orderTabletd"
                    // style={{
                    //   color:
                    //     parseInt(position.Netchg) > 0 ? "#39A245" : "#DF2123",
                    // }}
                  >
                    {position?.Netchg}
                  </td> */}
                  </tr>
                );
              })}
              <tr>
                <td className="px-4 py-2 border orderTabletd" colSpan="6"></td>
                <td className="px-4 py-2  orderTabletd">Total</td>
                <td className="px-4 py-2  orderTabletd">
                  {isNaN(totalPNL) ? 0 : totalPNL.toFixed(2)}
                </td>
                <td className="px-4 py-2  orderTabletd">
                  {isNaN(totalLTP) ? 0 : totalLTP.toFixed(2)}
                </td>
                <td className="px-4 py-2  orderTabletd"></td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : (
        <div className="p-4">
          {loading && <Loader />}
          <div className="flex flex-row items-center justify-between mb-4">
            <div className="flex  items-center ">
              <h1 className="text-xl font-bold mr-[36px]">Positions</h1>
              <div className="flex space-x-1">
                <button
                  onClick={() => {
                    setPositionStatusCount(
                      positionStatusCount.map((it, idx) => {
                        it.isSelected = false;

                        return it;
                      })
                    );
                    setIsAllSelected(true);
                  }}
                  className="px-3 py-1 border  bg-green-100 flex flex-row items-center"
                  style={{
                    fontSize: 14,
                    color: isAllSelected ? "#155724" : "#817E7E",
                    backgroundColor: isAllSelected ? "#D4EDDA" : "#FFFFFF",
                  }}
                >
                  ALL | 25
                </button>

                {positionStatusCount.map((item, index) => {
                  return (
                    <button
                      className="px-3 py-1 border"
                      key={index}
                      style={{ borderColor: "#817E7E" }}
                      onClick={() => {
                        setIsAllSelected(false);
                        setPositionStatusCount(
                          positionStatusCount.map((it, idx) => {
                            if (index === idx) {
                              it.isSelected = true;
                            } else {
                              it.isSelected = false;
                            }
                            return it;
                          })
                        );
                      }}
                      style={{
                        fontSize: 12,
                        color: item.isSelected ? "#155724" : "#817E7E",
                        backgroundColor: item.isSelected
                          ? "#D4EDDA"
                          : "#FFFFFF",
                      }}
                    >
                      {item.status} | {item.value}
                    </button>
                  );
                })}
              </div>
            </div>

            <div className="flex flex-row items-center z-0">
              <div className="mr-[7px]">
                <span
                  style={{ fontSize: 12, fontWeight: "500", color: "#817E7E" }}
                >
                  Today
                </span>
              </div>

              <div className="flex items-center justify-center ml-[8px] mr-[8px]  bg-gray-100">
                <button
                  className={`w-14 h-6 flex items-center rounded-full p-1 duration-300 ease-in-out`}
                  style={{
                    backgroundColor: isToggledTodayOverAll
                      ? "green"
                      : "green  ",
                  }}
                  onClick={() => handleToggle()}
                >
                  <div
                    style={{ backgroundColor: "white" }}
                    className={` w-4 h-4  rounded-full shadow-md transform duration-300 ease-in-out ${
                      isToggledTodayOverAll ? "translate-x-7" : "translate-x-1"
                    }`}
                  />
                </button>
              </div>
              <div className="mr-[21px]">
                <span
                  style={{ fontSize: 12, fontWeight: "500", color: "#817E7E" }}
                >
                  Overall
                </span>
              </div>
            </div>

            <div className="mr-[16PX]">
              <button>
                <img
                  src={DownloadIcon}
                  alt="download"
                  style={{ width: 14, height: 16 }}
                />
              </button>
            </div>
          </div>

          <table className="min-w-full bg-white orderTable">
            <thead className="orderTable">
              <tr>
                <th className="px-4 py-2">
                  <img
                    src={selectIcon}
                    alt="select option"
                    style={{ width: 16, height: 16 }}
                  />
                </th>
                {/* <th className="px-4 py-2 border">Type</th>
                <th className="px-4 py-2 border">Instrument</th>
                <th className="px-4 py-2 border">Buy Qty.</th>
                <th className="px-4 py-2 border">Sell Qty.</th>
                <th className="px-4 py-2 border">Buy Avg</th>
                <th className="px-4 py-2 border">Sell Avg</th>
                <th className="px-4 py-2 border">PNL</th>
                <th className="px-4 py-2 border">LTP</th> */}
                <th className="px-4 py-2 border">Type</th>
                <th className="px-4 py-2 border">Instrument</th>
                <th className="px-4 py-2 border">Buy Qty.</th>
                <th className="px-4 py-2 border">Sell Qty.</th>
                <th className="px-4 py-2 border">Buy Avg</th>
                <th className="px-4 py-2 border">Sell Avg</th>
                <th className="px-4 py-2 border">PNL</th>
                <th className="px-4 py-2 border">Ltp</th>
                {/* <th className="px-4 py-2 border">Net</th>
                <th className="px-4 py-2 border">Action</th> */}
              </tr>
            </thead>
            <tbody>
              {filterPositions()?.map((position, index) => {
                // console.log("positions : ", position);
                const Ltp = position?.last_traded_price;

                const BuyAT = position?.buy_qty * position?.buy_avg;
                const SELLAT = position?.sell_qty * position?.sell_avg;
                const old_profit = BuyAT - SELLAT;
                const NbuyAt = position?.buy_qty * Ltp;
                const NSellAt = position?.sell_qty * Ltp;
                const New_Profit = NbuyAt - NSellAt;
                const PNL = New_Profit - old_profit;
                return (
                  <tr key={index}>
                    <td className="px-4 py-2 border orderTabletd">
                      <img
                        src={selectIcon}
                        alt="select option"
                        style={{ width: 16, height: 16 }}
                      />
                    </td>
                    <td className="px-4 py-2 border orderTabletd">
                      <button
                        style={{
                          fontSize: 10,
                          paddingLeft: 4,
                          paddingRight: 4,
                          height: 20,
                          borderRadius: 3,
                          color: positionType(position.ptype).color,
                          backgroundColor: positionType(position.ptype)
                            .backgroundColor,
                        }}
                      >
                        {/* {`${position.ptype}  `} */}
                        {positionType(position.ptype).type}
                      </button>
                    </td>

                    <td
                      className="px-4 py-2 border relative orderTabletd"
                      style={{
                        fontSize: 14,
                        color: "#363636",
                        fontWeight: "700",
                      }}
                      onMouseOver={() => {
                        onMouseCallOverPositionInstrument(index);
                      }}
                      onMouseOut={() => {
                        onMouseCallOutPositionInstrument(index);
                      }}
                    >
                      <div className="relative">
                        <div>{position.instrument__trading_symbol}</div>

                        {position.isAddOrExitBtnOpen && (
                          <div className="addorexit">
                            <button
                              className="addBtn"
                              onClick={() =>
                                onOpenAddModal(
                                  position.buy_qty,
                                  position.sell_qty,
                                  position.instrument__trading_symbol,
                                  position.instrument__exchange_token,
                                  position.instrument__exchange,
                                  position.last_traded_price,
                                  position.instrument__lot_size
                                )
                              }
                            >
                              <p>Add</p>
                            </button>
                            <button
                              className="exitBtn"
                              onClick={() =>
                                onOpenExitModal(
                                  position.buy_qty,
                                  position.sell_qty,
                                  position.instrument__trading_symbol,
                                  position.instrument__exchange_token,
                                  position.instrument__exchange,
                                  position.last_traded_price,
                                  position.instrument__lot_size
                                )
                              }
                            >
                              <p>Exit</p>
                            </button>
                          </div>
                        )}
                      </div>
                    </td>
                    <td className="px-4 py-2 border orderTabletd">
                      {position.buy_qty}
                    </td>
                    <td className="px-4 py-2 border orderTabletd">
                      {position.sell_qty}
                    </td>
                    <td className="px-4 py-2 border orderTabletd">
                      {position.buy_avg}
                    </td>
                    <td className="px-4 py-2 border orderTabletd">
                      {position.sell_avg}
                    </td>
                    <td
                      className="px-4 py-2 border orderTabletd"
                      style={{
                        color: PNL >= 0 ? "#39A245" : "#DF2123",
                      }}
                    >
                      {/* {position.LTP} */}
                      {isNaN(PNL) ? 0 : PNL.toFixed(2)}
                    </td>
                    <td
                      className="px-4 py-2 border orderTabletd"
                      style={{
                        color: Ltp >= 0 ? "#39A245" : "#DF2123",
                      }}
                    >
                      {/* {position.PL} */}
                      {isNaN(Ltp) ? 0.0 : Ltp.toFixed(2)}
                    </td>
                    {/* <td
                    className="px-4 py-2 border orderTabletd"
                    // style={{
                    //   color:
                    //     parseInt(position.Netchg) > 0 ? "#39A245" : "#DF2123",
                    // }}
                  >
                    {position?.Netchg}
                  </td> */}
                  </tr>
                );
              })}
              <tr>
                <td className="px-4 py-2 border orderTabletd" colSpan="6"></td>
                <td className="px-4 py-2  orderTabletd">Total</td>
                <td className="px-4 py-2  orderTabletd">
                  {isNaN(totalPNL) ? 0 : totalPNL.toFixed(2)}
                </td>
                <td className="px-4 py-2  orderTabletd">
                  {isNaN(totalLTP) ? 0 : totalLTP.toFixed(2)}
                </td>
                <td className="px-4 py-2  orderTabletd"></td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
      {/* <BuyAndSellModal /> */}
    </Layout>
  );
}

export default Positions;
