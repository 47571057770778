import React, { useEffect, useContext, useState } from "react";
import MyContext from "../../context/MyContext";
import Navbar from "../../components/navbar/Navbar";
import rupeeIcon from "../../assets/icons/rupee.png";
import commoditiesIcon from "../../assets/icons/commodities.png";
import closedIcon from "../../assets/icons/closed.png";
import equitiesIcon from "../../assets/icons/equities.png";
import featuresIcon from "../../assets/icons/features.png";
import optionsIcon from "../../assets/icons/Options.png";
import currencyIcon from "../../assets/icons/currency.png";
import Layout from "../../components/layout/Layout";
import CreateChild from "../../components/broker/CreateChild";
import IntegrateChild from "../../components/broker/IntegrateChild";
import AccountList from "../../components/broker/AccountList";
import "../../App.css";

function AddChildAccount() {
  const {
    isDemoPage,
    userprofile,
    setWatchListShown,
    isChildAccount,
    setIsChildAccount,
    childAccount,
    brokers,
    setBrokers,
    getAccountList,
    getDashboardCall,
  } = useContext(MyContext);

  const [isToggled, setIsToggled] = useState(false);
  const [stockItem, setStockItem] = useState([
    { name: "Equities", type: "Trade in company shares", img: equitiesIcon },
    { name: "Currency", type: "Trade in USD, EURO & more", img: currencyIcon },
    { name: "Futures", type: "< Discover future to trades", img: featuresIcon },
    { name: "Options", type: "Find option to trade in", img: optionsIcon },
    {
      name: "Commodities",
      type: "Trade in goods and matals",
      img: commoditiesIcon,
    },
  ]);
  const [formData, setFormData] = useState({});
  const [selectedOption, setSelectedOption] = useState("");
  const [selectChildOption, setSelectChildOption] = useState("");
  const [integrateChildOrCreateChild, setIntegrateChildOrCreateChild] =
    useState(false); //false for integrate child and true create child option

  // const brokers = [
  //   { id: 1, name: 'Broker A', validity: '2023-12-31', multiplier: 1.5, trade: 'Yes', email: 'brokerA@example.com', username: 'brokerA' },
  //   { id: 2, name: 'Broker B', validity: '2024-06-30', multiplier: 2.0, trade: 'No', email: 'brokerB@example.com', username: 'brokerB' },
  //   { id: 3, name: 'Broker C', validity: '2025-01-15', multiplier: 1.8, trade: 'Yes', email: 'brokerC@example.com', username: 'brokerC' },
  //   { id: 4, name: 'Broker D', validity: '2023-08-20', multiplier: 1.3, trade: 'No', email: 'brokerD@example.com', username: 'brokerD' },
  //   { id: 5, name: 'Broker E', validity: '2023-11-01', multiplier: 2.2, trade: 'Yes', email: 'brokerE@example.com', username: 'brokerE' },
  //   { id: 6, name: 'Broker F', validity: '2024-03-11', multiplier: 1.1, trade: 'No', email: 'brokerF@example.com', username: 'brokerF' },
  //   { id: 7, name: 'Broker G', validity: '2024-01-19', multiplier: 1.9, trade: 'Yes', email: 'brokerG@example.com', username: 'brokerG' },
  //   { id: 8, name: 'Broker H', validity: '2023-07-22', multiplier: 1.4, trade: 'No', email: 'brokerH@example.com', username: 'brokerH' },
  //   { id: 9, name: 'Broker I', validity: '2024-02-28', multiplier: 1.6, trade: 'Yes', email: 'brokerI@example.com', username: 'brokerI' },
  //   { id: 10, name: 'Broker J', validity: '2024-04-14', multiplier: 2.1, trade: 'No', email: 'brokerJ@example.com', username: 'brokerJ' },
  //   { id: 11, name: 'Broker K', validity: '2023-10-25', multiplier: 1.7, trade: 'Yes', email: 'brokerK@example.com', username: 'brokerK' },
  //   { id: 12, name: 'Broker L', validity: '2023-09-10', multiplier: 1.2, trade: 'No', email: 'brokerL@example.com', username: 'brokerL' },
  // ];

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleChildChange = (event) => {
    setSelectChildOption(event.target.value);
  };

  const handleToggle = () => {
    setIntegrateChildOrCreateChild(!integrateChildOrCreateChild);
    setIsToggled(!isToggled);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log("Form submitted:", formData);
  };

  useEffect(() => {
    setIsChildAccount(true);
    setWatchListShown(false);
    return () => {
      setIsChildAccount(false);
    };
  });

  useEffect(() => {
    getAccountList();
  }, []);

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const renderInputOption = (selectedOption) => {
    // console.log("selected option : ", selectedOption);

    // AliceBlue ID (placeholder - Enter Your AliceBlue User)
    //         AliceBlue Password (placeholder - Enter Password)
    // 	AliceBlue TOTP (placeholder - TOTP)
    //         DOB (placeholder -Your DOB)
    //         AliceBlue APIKey (placeholder - API Key)
    switch (selectedOption) {
      case "Aliceblue":
        return (
          <div>
            <div className="flex flex-row items-center justify-between">
              <div className="flex flex-col mb-[5px]">
                <label
                  htmlFor="AliceBlue ID"
                  className="mb-[5px]"
                  style={{ fontSize: 14, color: "#817e7" }}
                >
                  AliceBlue ID
                </label>
                <input
                  type="text"
                  id="AliceBlueID"
                  name="AliceBlueID"
                  placeholder="Enter Your AliceBlue User"
                  // value={formData.email}
                  onChange={handleChangeInput}
                  required
                  style={{
                    padding: "10px 5px",
                    borderRadius: 4,
                    borderWidth: 1,
                    borderColor: "#817e7e",
                    backgroundColor: "#ffffff",
                  }}
                />
              </div>

              <div className="flex flex-col mb-[5px]">
                <label
                  htmlFor="AliceBluePassword"
                  className="mb-[5px]"
                  style={{ fontSize: 14, color: "#817e7" }}
                >
                  AliceBlue Password
                </label>
                <input
                  type="text"
                  id="AliceBluePassword"
                  name="AliceBluePassword"
                  placeholder="Enter Password"
                  // value={formData.email}
                  onChange={handleChangeInput}
                  required
                  style={{
                    padding: "10px 5px",
                    borderRadius: 4,
                    borderWidth: 1,
                    borderColor: "#817e7e",
                    backgroundColor: "#ffffff",
                  }}
                />
              </div>
            </div>
          </div>
        );

      case "Angelone":
        return <div>Angel one</div>;

      default:
        return <></>;
    }
  };

  useEffect(() => {
    if (isChildAccount) {
      if (isDemoPage) {
        setWatchListShown(false);
      } else {
        setWatchListShown(true);
      }
    }
  });

  return (
    <Layout className="min-h-screen flex flex-col">
      <div
        className="flex flex-row mt-[25px]  pl-[20px] pr-[20px]"
        style={{ width: "100%", height: "100vh", borderWidth: 0 }}
      >
        <div
          className="mr-[10px] p-[10px]"
          style={{
            width: "30%",
            height: "85%",
            borderWidth: 1,
            borderColor: "#e6e6e6",
          }}
        >
          <div className="flex flex-row items-center justify-center mb-[10px] mt-[10px]">
            <div className="mr-[7px]">
              <span style={{ fontSize: 15, fontWeight: "500" }}>
                Integrate Child
              </span>
            </div>

            <div className="flex items-center justify-center ml-[8px] mr-[8px]  bg-gray-100">
              <button
                className={`w-14 h-6 flex items-center rounded-full p-1 duration-300 ease-in-out`}
                style={{ backgroundColor: isToggled ? "green" : "green  " }}
                onClick={handleToggle}
              >
                <div
                  style={{ backgroundColor: "white" }}
                  className={` w-4 h-4  rounded-full shadow-md transform duration-300 ease-in-out ${
                    isToggled ? "translate-x-7" : "translate-x-1"
                  }`}
                />
              </button>
            </div>
            <div className="mr-[21px]">
              <span style={{ fontSize: 15, fontWeight: "500" }}>
                Create Child
              </span>
            </div>
          </div>

          {integrateChildOrCreateChild ? (
            <CreateChild
              getAccountList={getAccountList}
              getDashboardCall={getDashboardCall}
            />
          ) : (
            <IntegrateChild
              isBrokerIntegrationPage={false}
              getAccountList={getAccountList}
              getDashboardCall={getDashboardCall}
            />
          )}
        </div>

        <div
          style={{
            width: "70%",
            height: "85%",
            borderWidth: 1,
            borderColor: "#e6e6e6",
          }}
        >
          <div className="account_list">Account's List</div>
          <AccountList
            brokers={brokers}
            itemsPerPage={10}
            setBrokers={setBrokers}
            getAccountList={getAccountList}
          />
        </div>
      </div>
    </Layout>
  );
}

export default AddChildAccount;
