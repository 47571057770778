import React, { useState, useContext, useEffect } from "react";
import { toast } from "react-toastify";
import MyContext from "../../context/MyContext";

function IntegrateChild({
  getAccountList,
  isBrokerIntegrationPage,
  getDashboardCall,
}) {
  const { setLoading, childAccount } = useContext(MyContext);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectChildOption, setSelectChildOption] = useState("");
  const [formData, setFormData] = useState({});
  const [angleOneFormData, setAngleOneFormData] = useState({
    angelone_userid: "",
    angelone_pin: "",
    angelone_qrcode: "",
  });
  const [selectedChild, setSelectedChild] = useState("");
  const [odinUserId, setOdinUserId] = useState("");
  const [odinUserPassword, setOdinUserPassword] = useState("");
  const [odinUserFA, setOdinUserFA] = useState("");

  const handleChildChange = (event) => {
    setSelectChildOption(event.target.value);
  };

  const renderInputOption = (selectedOption) => {
    // console.log("selected option : ", selectedOption);

    // AliceBlue ID (placeholder - Enter Your AliceBlue User)
    //         AliceBlue Password (placeholder - Enter Password)
    // 	AliceBlue TOTP (placeholder - TOTP)
    //         DOB (placeholder -Your DOB)
    //         AliceBlue APIKey (placeholder - API Key)
    switch (selectedOption) {
      case "Aliceblue":
        return (
          <div style={{ width: "100%" }}>
            <div className="flex flex-row items-center justify-between mb-[16px] mt-[16px]">
              <div className="flex flex-col mb-[5px]" style={{ width: "50%" }}>
                <label
                  htmlFor="AliceBlue ID"
                  className="mb-[5px]"
                  style={{ color: "#817e7e", fontSize: 14 }}
                >
                  AliceBlue ID
                </label>
                <input
                  type="text"
                  id="AliceBlueID"
                  name="AliceBlueID"
                  placeholder="Enter Your AliceBlue User"
                  // value={formData.email}
                  onChange={handleChangeInput}
                  required
                  style={{
                    padding: "10px 5px",
                    borderRadius: 4,
                    borderWidth: 1,
                    borderColor: "#817e7e",
                    backgroundColor: "#ffffff",
                  }}
                />
              </div>

              <div className="flex flex-col mb-[5px]" style={{ width: "45%" }}>
                <label
                  htmlFor="AliceBluePassword"
                  className="mb-[5px]"
                  style={{ color: "#817e7e", fontSize: 14 }}
                >
                  AliceBlue Password
                </label>
                <input
                  type="text"
                  id="AliceBluePassword"
                  name="AliceBluePassword"
                  placeholder="Enter Password"
                  // value={formData.email}
                  onChange={handleChangeInput}
                  required
                  style={{
                    padding: "10px 5px",
                    borderRadius: 4,
                    borderWidth: 1,
                    borderColor: "#817e7e",
                    backgroundColor: "#ffffff",
                  }}
                />
              </div>
            </div>

            <div className="flex flex-row items-center justify-between mb-[16px]">
              <div className="flex flex-col mb-[5px]" style={{ width: "50%" }}>
                <label
                  htmlFor="totp"
                  className="mb-[5px]"
                  style={{ color: "#817e7e", fontSize: 14 }}
                >
                  AliceBlue TOTP
                </label>
                <input
                  type="text"
                  id="AliceBlueTotp"
                  name="AliceBlueTotp"
                  placeholder="Your TOTP"
                  // value={formData.email}
                  onChange={handleChangeInput}
                  required
                  style={{
                    padding: "10px 5px",
                    borderRadius: 4,
                    borderWidth: 1,
                    borderColor: "#817e7e",
                    backgroundColor: "#ffffff",
                  }}
                />
              </div>

              <div className="flex flex-col mb-[5px]" style={{ width: "45%" }}>
                <label
                  htmlFor="AliceBluePassword"
                  className="mb-[5px]"
                  style={{ fontSize: 14, color: "#817e7" }}
                >
                  DOB
                </label>
                <input
                  type="date"
                  id="AliceBlueDob"
                  name="AliceBlueDob"
                  placeholder="Your DOB"
                  // value={formData.email}
                  onChange={handleChangeInput}
                  required
                  style={{
                    padding: "10px 5px",
                    borderRadius: 4,
                    borderWidth: 1,
                    borderColor: "#817e7e",
                    backgroundColor: "#ffffff",
                  }}
                />
              </div>
            </div>

            <div className="flex flex-row items-center justify-between ">
              <div className="flex flex-col mb-[5px]" style={{ width: "50%" }}>
                <label
                  htmlFor="AliceBlueAPIKey"
                  className="mb-[5px]"
                  style={{ color: "#817e7e", fontSize: 14 }}
                >
                  AliceBlue APIKey
                </label>
                <input
                  type="text"
                  id="AliceBlueAPIKey"
                  name="AliceBlueAPIKey"
                  placeholder="API Key"
                  // value={formData.email}
                  onChange={handleChangeInput}
                  required
                  style={{
                    padding: "10px 5px",
                    borderRadius: 4,
                    borderWidth: 1,
                    borderColor: "#817e7e",
                    backgroundColor: "#ffffff",
                  }}
                />
              </div>
            </div>
          </div>
        );

      case "Angelone":
        return (
          <div style={{ width: "100%" }}>
            <div className="flex flex-row mt-[16px] items-center justify-between">
              <div
                className="flex flex-col mb-[16px] "
                style={{ width: "50%" }}
              >
                <label
                  htmlFor="AngelOneUserID"
                  className="mb-[5px]"
                  style={{ fontSize: 14, color: "#817e7" }}
                >
                  AngelOne User ID
                </label>
                <input
                  type="text"
                  id="AngelOneUserID"
                  name="AngelOneUserID"
                  placeholder="Enter Your AngelOne User"
                  value={angleOneFormData.angelone_userid}
                  onChange={(event) => {
                    let data = {
                      ...angleOneFormData,
                      angelone_userid: event.target.value,
                    };
                    setAngleOneFormData(data);
                  }}
                  required
                  style={{
                    padding: "10px 5px",
                    borderRadius: 4,
                    borderWidth: 1,
                    borderColor: "#817e7e",
                    backgroundColor: "#ffffff",
                  }}
                />
              </div>

              <div className="flex flex-col mb-[16px]" style={{ width: "45%" }}>
                <label
                  htmlFor="AngelOnePIN"
                  className="mb-[5px]"
                  style={{ fontSize: 14, color: "#817e7" }}
                >
                  AngelOne PIN
                </label>
                <input
                  type="number"
                  id="AngelOnePIN"
                  name="AngelOnePIN"
                  placeholder="Enter Password"
                  value={angleOneFormData.angelone_pin}
                  onChange={(event) => {
                    let data = {
                      ...angleOneFormData,
                      angelone_pin: event.target.value,
                    };
                    setAngleOneFormData(data);
                  }}
                  required
                  style={{
                    padding: "10px 5px",
                    borderRadius: 4,
                    borderWidth: 1,
                    borderColor: "#817e7e",
                    backgroundColor: "#ffffff",
                  }}
                />
              </div>
            </div>

            <div className="flex flex-row items-center justify-between">
              <div className="flex flex-col " style={{ width: "50%" }}>
                <label
                  htmlFor="AliceBlue ID"
                  className="mb-[5px]"
                  style={{ fontSize: 14, color: "#817e7" }}
                >
                  AngelOne QRCode String
                </label>
                <input
                  type="text"
                  id="AngelOneQRCode"
                  name="AngelOneQRCode"
                  placeholder="Your TOTP"
                  value={angleOneFormData.angelone_qrcode}
                  onChange={(event) => {
                    let data = {
                      ...angleOneFormData,
                      angelone_qrcode: event.target.value,
                    };
                    setAngleOneFormData(data);
                  }}
                  required
                  style={{
                    padding: "10px 5px",
                    borderRadius: 4,
                    borderWidth: 1,
                    borderColor: "#817e7e",
                    backgroundColor: "#ffffff",
                  }}
                />
              </div>
            </div>
          </div>
        );

      case "Odin":
        return (
          <div style={{ width: "100%" }}>
            <div className="flex flex-row items-center justify-between mb-[16px] mt-[16px]">
              <div className="flex flex-col mb-[5px]" style={{ width: "50%" }}>
                <label
                  htmlFor="Odinuserid"
                  className="mb-[5px]"
                  style={{ color: "#817e7e", fontSize: 14 }}
                >
                  Odin user id
                </label>
                <input
                  type="text"
                  id="Odinuserid"
                  name="Odinuserid"
                  placeholder="Enter Your Odin user id"
                  value={odinUserId}
                  onChange={(e) => setOdinUserId(e.target.value)}
                  required
                  style={{
                    padding: "10px 5px",
                    borderRadius: 4,
                    borderWidth: 1,
                    borderColor: "#817e7e",
                    backgroundColor: "#ffffff",
                  }}
                />
              </div>

              <div className="flex flex-col mb-[5px]" style={{ width: "45%" }}>
                <label
                  htmlFor="Odinpassword"
                  className="mb-[5px]"
                  style={{ color: "#817e7e", fontSize: 14 }}
                >
                  Odin password
                </label>
                <input
                  type="text"
                  id="Odinpassword"
                  name="Odinpassword"
                  placeholder="Enter Your Password"
                  value={odinUserPassword}
                  onChange={(e) => setOdinUserPassword(e.target.value)}
                  required
                  style={{
                    padding: "10px 5px",
                    borderRadius: 4,
                    borderWidth: 1,
                    borderColor: "#817e7e",
                    backgroundColor: "#ffffff",
                  }}
                />
              </div>
            </div>

            <div className="flex flex-row items-center justify-between">
              <div className="flex flex-col mb-[5px]" style={{ width: "50%" }}>
                <label
                  htmlFor="ODINFA"
                  className="mb-[5px]"
                  style={{ color: "#817e7e", fontSize: 14 }}
                >
                  ODIN FA
                </label>
                <input
                  type="text"
                  id="ODINFA"
                  name="ODINFA"
                  placeholder="Your FA"
                  value={odinUserFA}
                  onChange={(e) => setOdinUserFA(e.target.value)}
                  required
                  style={{
                    padding: "10px 5px",
                    borderRadius: 4,
                    borderWidth: 1,
                    borderColor: "#817e7e",
                    backgroundColor: "#ffffff",
                  }}
                />
              </div>
            </div>
          </div>
        );

      case "Tradelab":
        return (
          <div style={{ width: "100%" }}>
            <div className="flex flex-row items-center justify-between  mt-[16px]">
              <div className="flex flex-col mb-[5px]" style={{ width: "50%" }}>
                <label
                  htmlFor="Tradelabuserid"
                  className="mb-[5px]"
                  style={{ color: "#817e7e", fontSize: 14 }}
                >
                  Tradelab user id
                </label>
                <input
                  type="text"
                  id="Tradelabuserid"
                  name="Tradelabuserid"
                  placeholder="Enter Your Tradelab user id"
                  // value={formData.email}
                  onChange={handleChangeInput}
                  required
                  style={{
                    padding: "10px 5px",
                    borderRadius: 4,
                    borderWidth: 1,
                    borderColor: "#817e7e",
                    backgroundColor: "#ffffff",
                  }}
                />
              </div>
            </div>
          </div>
        );

      case "Zebu":
        return (
          <div style={{ width: "100%" }}>
            <div className="flex flex-row items-center justify-between mb-[16px] mt-[16px]">
              <div className="flex flex-col mb-[5px]" style={{ width: "50%" }}>
                <label
                  htmlFor="zebuapikey"
                  className="mb-[5px]"
                  style={{ color: "#817e7e", fontSize: 14 }}
                >
                  API Key
                </label>
                <input
                  type="text"
                  id="ZEBUAPIKey"
                  name="ZEBUAPIKey"
                  placeholder="Enter Your Zebu API Key"
                  // value={formData.email}
                  onChange={handleChangeInput}
                  required
                  style={{
                    padding: "10px 5px",
                    borderRadius: 4,
                    borderWidth: 1,
                    borderColor: "#817e7e",
                    backgroundColor: "#ffffff",
                  }}
                />
              </div>

              <div className="flex flex-col mb-[5px]" style={{ width: "45%" }}>
                <label
                  htmlFor="Zebuvc"
                  className="mb-[5px]"
                  style={{ color: "#817e7e", fontSize: 14 }}
                >
                  VC
                </label>
                <input
                  type="text"
                  id="ZEBUVC"
                  name="ZEBUVC"
                  placeholder="Your VC"
                  // value={formData.email}
                  onChange={handleChangeInput}
                  required
                  style={{
                    padding: "10px 5px",
                    borderRadius: 4,
                    borderWidth: 1,
                    borderColor: "#817e7e",
                    backgroundColor: "#ffffff",
                  }}
                />
              </div>
            </div>

            <div className="flex flex-row items-center justify-between mb-[16px]">
              <div className="flex flex-col mb-[5px]" style={{ width: "50%" }}>
                <label
                  htmlFor="Zebu Client ID"
                  className="mb-[5px]"
                  style={{ color: "#817e7e", fontSize: 14 }}
                >
                  Client ID
                </label>
                <input
                  type="text"
                  id="ZebuClientID"
                  name="ZebuClientID"
                  placeholder="Enter Zebu Client Id"
                  // value={formData.email}
                  onChange={handleChangeInput}
                  required
                  style={{
                    padding: "10px 5px",
                    borderRadius: 4,
                    borderWidth: 1,
                    borderColor: "#817e7e",
                    backgroundColor: "#ffffff",
                  }}
                />
              </div>

              <div className="flex flex-col mb-[5px]" style={{ width: "45%" }}>
                <label
                  htmlFor="ZebuPassword"
                  className="mb-[5px]"
                  style={{ fontSize: 14, color: "#817e7" }}
                >
                  password
                </label>
                <input
                  type="text"
                  id="zebupassword"
                  name="zebupassword"
                  placeholder="Enter zebu password"
                  // value={formData.email}
                  onChange={handleChangeInput}
                  required
                  style={{
                    padding: "10px 5px",
                    borderRadius: 4,
                    borderWidth: 1,
                    borderColor: "#817e7e",
                    backgroundColor: "#ffffff",
                  }}
                />
              </div>
            </div>

            <div className="flex flex-row items-center justify-between ">
              <div className="flex flex-col mb-[5px]" style={{ width: "50%" }}>
                <label
                  htmlFor="Zebu2FA"
                  className="mb-[5px]"
                  style={{ color: "#817e7e", fontSize: 14 }}
                >
                  2FA
                </label>
                <input
                  type="text"
                  id="zebu2FA"
                  name="zebu2FA"
                  placeholder="2FA"
                  // value={formData.email}
                  onChange={handleChangeInput}
                  required
                  style={{
                    padding: "10px 5px",
                    borderRadius: 4,
                    borderWidth: 1,
                    borderColor: "#817e7e",
                    backgroundColor: "#ffffff",
                  }}
                />
              </div>
            </div>
          </div>
        );

      case "Flattrade":
        return (
          <div style={{ width: "100%" }}>
            <div className="flex flex-row items-center justify-between mb-[16px] mt-[16px]">
              <div className="flex flex-col mb-[5px]" style={{ width: "50%" }}>
                <label
                  htmlFor="FlattradeAPIKEY"
                  className="mb-[5px]"
                  style={{ color: "#817e7e", fontSize: 14 }}
                >
                  Flattrade API KEY
                </label>
                <input
                  type="text"
                  id="FlattradeAPIKEY"
                  name="FlattradeAPIKEY"
                  placeholder="Enter Your API KEY"
                  // value={formData.email}
                  onChange={handleChangeInput}
                  required
                  style={{
                    padding: "10px 5px",
                    borderRadius: 4,
                    borderWidth: 1,
                    borderColor: "#817e7e",
                    backgroundColor: "#ffffff",
                  }}
                />
              </div>

              <div className="flex flex-col mb-[5px]" style={{ width: "40%" }}>
                <label
                  htmlFor="AliceBluePassword"
                  className="mb-[5px]"
                  style={{ color: "#817e7e", fontSize: 14 }}
                >
                  Flattrade API SEC KEY
                </label>
                <input
                  type="text"
                  id="FlattradeAPISECKEY"
                  name="FlattradeAPISECKEY"
                  placeholder="API SEC"
                  // value={formData.email}
                  onChange={handleChangeInput}
                  required
                  style={{
                    padding: "10px 5px",
                    borderRadius: 4,
                    borderWidth: 1,
                    borderColor: "#817e7e",
                    backgroundColor: "#ffffff",
                  }}
                />
              </div>
            </div>

            <div className="flex flex-row items-center justify-between ">
              <div className="flex flex-col mb-[5px]" style={{ width: "50%" }}>
                <label
                  htmlFor="FlattradeUSERID"
                  className="mb-[5px]"
                  style={{ color: "#817e7e", fontSize: 14 }}
                >
                  Flattrade USER ID
                </label>
                <input
                  type="text"
                  id="FlattradeUSERID"
                  name="FlattradeUSERID"
                  placeholder="USER ID"
                  // value={formData.email}
                  onChange={handleChangeInput}
                  required
                  style={{
                    padding: "10px 5px",
                    borderRadius: 4,
                    borderWidth: 1,
                    borderColor: "#817e7e",
                    backgroundColor: "#ffffff",
                  }}
                />
              </div>

              <div className="flex flex-col mb-[5px]" style={{ width: "40%" }}>
                <label
                  htmlFor="FlattradePassword"
                  className="mb-[5px]"
                  style={{ fontSize: 14, color: "#817e7" }}
                >
                  Flattrade Password
                </label>
                <input
                  type="text"
                  id="FlattradePassword"
                  name="FlattradePassword"
                  placeholder="Password"
                  // value={formData.email}
                  onChange={handleChangeInput}
                  required
                  style={{
                    padding: "10px 5px",
                    borderRadius: 4,
                    borderWidth: 1,
                    borderColor: "#817e7e",
                    backgroundColor: "#ffffff",
                  }}
                />
              </div>
            </div>
          </div>
        );

      case "ProfitMart":
        return (
          <div style={{ width: "100%" }}>
            <div className="flex flex-row items-center justify-between mb-[16px] mt-[16px]">
              <div className="flex flex-col mb-[5px]" style={{ width: "50%" }}>
                <label
                  htmlFor="ProfitMartApiKey"
                  className="mb-[5px]"
                  style={{ color: "#817e7e", fontSize: 14 }}
                >
                  API Key
                </label>
                <input
                  type="text"
                  id="ProfitMartApiKey"
                  name="ProfitMartApiKey"
                  placeholder="Enter ProfiltMart API Key"
                  // value={formData.email}
                  onChange={handleChangeInput}
                  required
                  style={{
                    padding: "10px 5px",
                    borderRadius: 4,
                    borderWidth: 1,
                    borderColor: "#817e7e",
                    backgroundColor: "#ffffff",
                  }}
                />
              </div>

              <div className="flex flex-col mb-[5px]" style={{ width: "45%" }}>
                <label
                  htmlFor="ProfiltMartvc"
                  className="mb-[5px]"
                  style={{ color: "#817e7e", fontSize: 14 }}
                >
                  VC
                </label>
                <input
                  type="text"
                  id="ProfiltMartVC"
                  name="ProfiltMartVC"
                  placeholder="Your VC"
                  // value={formData.email}
                  onChange={handleChangeInput}
                  required
                  style={{
                    padding: "10px 5px",
                    borderRadius: 4,
                    borderWidth: 1,
                    borderColor: "#817e7e",
                    backgroundColor: "#ffffff",
                  }}
                />
              </div>
            </div>

            <div className="flex flex-row items-center justify-between mb-[16px]">
              <div className="flex flex-col mb-[5px]" style={{ width: "50%" }}>
                <label
                  htmlFor="ProfiltMart Client ID"
                  className="mb-[5px]"
                  style={{ color: "#817e7e", fontSize: 14 }}
                >
                  Client ID
                </label>
                <input
                  type="text"
                  id="ProfiltMartClientID"
                  name="ProfiltMartClientID"
                  placeholder="Enter ProfiltMart Client Id"
                  // value={formData.email}
                  onChange={handleChangeInput}
                  required
                  style={{
                    padding: "10px 5px",
                    borderRadius: 4,
                    borderWidth: 1,
                    borderColor: "#817e7e",
                    backgroundColor: "#ffffff",
                  }}
                />
              </div>

              <div className="flex flex-col mb-[5px]" style={{ width: "45%" }}>
                <label
                  htmlFor="ProfiltMartPassword"
                  className="mb-[5px]"
                  style={{ fontSize: 14, color: "#817e7" }}
                >
                  password
                </label>
                <input
                  type="text"
                  id="ProfiltMartpassword"
                  name="ProfiltMartpassword"
                  placeholder="Enter ProfiltMart Password"
                  // value={formData.email}
                  onChange={handleChangeInput}
                  required
                  style={{
                    padding: "10px 5px",
                    borderRadius: 4,
                    borderWidth: 1,
                    borderColor: "#817e7e",
                    backgroundColor: "#ffffff",
                  }}
                />
              </div>
            </div>

            <div className="flex flex-row items-center justify-between ">
              <div className="flex flex-col mb-[5px]" style={{ width: "50%" }}>
                <label
                  htmlFor="ProfiltMart2FA"
                  className="mb-[5px]"
                  style={{ color: "#817e7e", fontSize: 14 }}
                >
                  2FA
                </label>
                <input
                  type="text"
                  id="ProfiltMart2FA"
                  name="ProfiltMart2FA"
                  placeholder="2FA"
                  // value={formData.email}
                  onChange={handleChangeInput}
                  required
                  style={{
                    padding: "10px 5px",
                    borderRadius: 4,
                    borderWidth: 1,
                    borderColor: "#817e7e",
                    backgroundColor: "#ffffff",
                  }}
                />
              </div>
            </div>
          </div>
        );

      default:
        return <></>;
    }
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleSubmit = async (event) => {
    // console.log("selectedchildoption:", selectedOption);

    event.preventDefault();
    setLoading(true);
    const sessionID = JSON.parse(localStorage.getItem("sessionID"));
    if (selectedOption === "Angelone") {
      if (isBrokerIntegrationPage) {
        const body = {
          angelone_userid: angleOneFormData.angelone_userid,
          angelone_pin: angleOneFormData.angelone_pin,
          angelone_qrcode: angleOneFormData.angelone_qrcode,
        };

        // console.log("angleone body : ", body);

        await fetch("https://app.stoxviews.com/api/angelone/brokersetup", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionID}`,
          },
          body: JSON.stringify(body),
        })
          .then((res) => res.json())
          .then((value) => {
            // console.log("angleone result : ", value);
            setAngleOneFormData({
              angelone_userid: "",
              angelone_pin: "",
              angelone_qrcode: "",
            });
            if (value.status) {
              setLoading(false);

              toast.success(`${value.message}`, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            } else {
              toast.error(`${value.message}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
              setLoading(false);
            }
          })
          .catch((err) => {
            setLoading(false);
            toast.error(`Something went wrong`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            console.log("Error : ", err);
          });
      } else {
        const body = {
          angelone_userid: angleOneFormData.angelone_userid,
          angelone_pin: angleOneFormData.angelone_pin,
          angelone_qrcode: angleOneFormData.angelone_qrcode,
          schild: selectChildOption,
        };

        // console.log("integrate child angleone body : ", body);

        await fetch("https://app.stoxviews.com/api/angelone/addchildangle", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionID}`,
          },
          body: JSON.stringify(body),
        })
          .then((res) => res.json())
          .then((value) => {
            // console.log("angleone child integrate : ", value);

            if (value.status) {
              setLoading(false);
              getAccountList();
              getDashboardCall();
              toast.success(`${value.data}`, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            } else {
              setLoading(false);
              toast.error(`${value.message}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            }
          })
          .catch((err) => {
            setLoading(false);
            toast.error(`Something went wrong`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            console.log("Error : ", err);
          });
      }
    } else if (selectedOption === "Odin") {
      if (isBrokerIntegrationPage) {
        const body = {
          odin_client_id: odinUserId,
          odin_FA: odinUserFA,
          odin_password: odinUserPassword,
        };
        // console.log("odin integration : ", body);

        await fetch("https://app.stoxviews.com/api/odin/brokersetup", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionID}`,
          },
          body: JSON.stringify(body),
        })
          .then((res) => res.json())
          .then((value) => {
            // console.log("Odin integrate result : ", value);

            if (value.status) {
              setLoading(false);
              getAccountList();
              getDashboardCall();
              toast.success(`${value.message}`, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            } else {
              setLoading(false);
              toast.error(`${value.message}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            }
          })
          .catch((err) => {
            setLoading(false);
            toast.error(`Something went wrong`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            console.log("Error : ", err);
          });
      } else {
        const body = {
          odin_client_id: odinUserId,
          odin_FA: odinUserFA,
          odin_password: odinUserPassword,
          schild: selectChildOption,
        };
        // console.log("odin child integration body : ", body);

        await fetch("https://app.stoxviews.com/api/odin/addchildodin", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionID}`,
          },
          body: JSON.stringify(body),
        })
          .then((res) => res.json())
          .then((value) => {
            // console.log("Odin integrate result : ", value);

            if (value.status) {
              setLoading(false);
              getAccountList();
              getDashboardCall();
              toast.success(`${value.message}`, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            } else {
              setLoading(false);
              toast.error(`${value.message}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            }
          })
          .catch((err) => {
            setLoading(false);
            toast.error(`Something went wrong`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            console.log("Error : ", err);
          });
      }
    }
  };

  const onSelectChildCall = async (item) => {
    //console.log("body sailha : ", item);
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="flex flex-col">
          <label
            htmlFor="options"
            className="mb-[5px]"
            style={{ color: "#817e7e", fontSize: 14 }}
          >
            Select Your Broker
          </label>
          <select
            id="options"
            value={selectedOption}
            onChange={handleChange}
            style={{
              padding: "10px 5px",
              borderRadius: 4,
              borderWidth: 1,
              borderColor: "#817e7e",
              color: "#817e7e",
            }}
          >
            <option value="">Select Your Broker</option>
            <option value="Aliceblue">Aliceblue</option>
            <option value="Angelone">Angelone</option>
            <option value="Odin">Odin</option>
            <option value="Tradelab">Tradelab</option>
            <option value="Zebu">Zebu</option>
            <option value="Flattrade">Flattrade</option>
            <option value="ProfitMart">ProfitMart</option>
            <option value="Kotak">Kotak</option>
          </select>
        </div>

        {renderInputOption(selectedOption)}

        {!isBrokerIntegrationPage ? (
          <div className="flex relative items-center  mt-[24px]">
            <div className="flex flex-col w-full">
              <label
                htmlFor="options"
                className="mb-[5px] w-full"
                style={{ color: "#817e7e", fontSize: 14 }}
              >
                Select Child
              </label>

              <div className="w-full flex items-center justify-between">
                <select
                  id="options"
                  value={selectChildOption}
                  onChange={handleChildChange}
                  style={{
                    padding: "10px 5px",
                    borderRadius: 4,
                    borderWidth: 1,
                    borderColor: "#817e7e",
                    color: "#817e7e",
                    width: "50%",
                  }}
                >
                  <option value="">Choose...</option>

                  {childAccount.map((item, index) => {
                    // console.log(item);

                    return (
                      item.user_id__broker === "stoxview" && (
                        <option value={item.user_id_id}>
                          {item.user_id__user_id__username}
                        </option>
                      )
                    );
                  })}
                </select>
                <button
                  type="submit"
                  className="py-[9px] px-[9px] w-[96px]"
                  style={{
                    fontSize: 12,
                    borderRadius: 4,
                    backgroundColor: "#d4edda",
                  }}
                >
                  Integrate
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex relative items-center  mt-[24px]">
            <div className="flex flex-col w-full">
              <div className="w-full flex items-center justify-end">
                <button
                  type="submit"
                  className="py-[9px] px-[9px] w-[96px]"
                  style={{
                    fontSize: 12,
                    borderRadius: 4,
                    backgroundColor: "#d4edda",
                  }}
                >
                  Integrate
                </button>
              </div>
            </div>
          </div>
        )}
      </form>
    </div>
  );
}

export default IntegrateChild;
