import React, { useState, useEffect, useContext } from "react";
import deleteIcon from "../../assets/icons/del.png";
import editIcon from "../../assets/icons/editing.png";
import correctIcon from "../../assets/icons/correct.png";
import Moment from "react-moment";
import { toast } from "react-toastify";
import Loader from "../loader/Loader";
import MyContext from "../../context/MyContext";

function AccountList({ brokers, itemsPerPage, setBrokers, getAccountList }) {
  const { setLoading, loading } = useContext(MyContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [validity, setValidity] = useState("");
  const [multiplier, setMultiplier] = useState("");
  const totalPages = Math.ceil(brokers.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = brokers.slice(indexOfFirstItem, indexOfLastItem);

  const handleClick = (page) => {
    setCurrentPage(page);
  };

  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const onDeleteRow = async (userid) => {
    // console.log("child id :", userid);
    setLoading(true);
    const sessionID = JSON.parse(localStorage.getItem("sessionID"));
    const body = { child_id: userid };
    await fetch("https://app.stoxviews.com/api/deletechild", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionID}`,
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((value) => {
        // console.log("CHILD ACCOUNT DELETE : ", value);

        if (value.status) {
          setLoading(false);
          // const newBrokers = brokers.filter(
          //   (item, index) => item.user_id !== userid
          // );
          // setBrokers(newBrokers);
          toast.success("Child account deleted Successfully", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          getAccountList();
        } else {
          toast.error(`Something went wrong`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(`Something went wrong`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        console.log("Error : ", err);
      });
  };

  const handleToggle = (userid) => {
    const newBrokers = brokers.map((item, index) => {
      if (item.user_id === userid) {
        item.trade_on = item.trade_on ? false : true;
      }
      return item;
    });

    setBrokers(newBrokers);
  };

  const onClickEdit = (index) => {
    const newBrokers = brokers.map((item, idx) => {
      if (index === idx) {
        item.isEditEnabled = true;
      }
      return item;
    });
    setBrokers(newBrokers);
  };

  const onUpdateChild = async (childid, tradeon, index) => {
    setLoading(true);
    const sessionID = JSON.parse(localStorage.getItem("sessionID"));

    const body = {
      child_id: childid,
      qty_mul: multiplier,
      trade_on: tradeon,
      validity: validity.replace(/-/g, "/"),
    };

    // console.log("edit : ", body);

    await fetch("https://app.stoxviews.com/api/updatechild", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionID}`,
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((value) => {
        // console.log("CHILD ACCOUNT UPDATE : ", value);

        if (value.status) {
          setLoading(false);

          toast.success("Child account update Successfully", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          getAccountList();
        } else {
          toast.error(`Something went wrong`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setLoading(false);
        }
      })
      .catch((err) => {
        const newBrokers = brokers.map((item, idx) => {
          if (index === idx) {
            item.isEditEnabled = false;
          }
          return item;
        });
        setBrokers(newBrokers);
        setLoading(false);
        toast.error(`Something went wrong`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        console.log("Error : ", err);
      });
  };

  return (
    <>
      <div style={{ height: "90%", overflowY: "auto", marginBottom: 10 }}>
        {loading && <Loader />}
        <table style={{ width: "100%" }}>
          <thead>
            <tr className="broker">
              <th>Broker ID</th>
              <th>Broker's Name</th>
              <th>Validity</th>
              <th>Multiplier</th>
              <th>Trade</th>
              <th>Email</th>
              <th>Username</th>
              <th>Actions</th>
            </tr>
          </thead>

          {currentItems.map((broker, index) => {
            return (
              <tr className="brokerTd" key={index}>
                <td>{broker?.user_id}</td>
                <td>{broker?.user_id__broker}</td>
                <td>
                  {!broker?.isEditEnabled ? (
                    <Moment format="YYYY/MM/DD">
                      {broker?.user_id__account_expiry}
                    </Moment>
                  ) : (
                    <input
                      type="date"
                      id="validity"
                      name="validity"
                      value={validity}
                      onChange={(e) => setValidity(e.target.value)}
                      required
                      style={{
                        padding: "4px 5px",
                        borderRadius: 4,
                        borderWidth: 1,
                        borderColor: "#817e7e",
                        backgroundColor: "#ffffff",
                        width: "80%",
                      }}
                    />
                  )}
                </td>
                <td>
                  {broker?.isEditEnabled ? (
                    <input
                      required
                      type="number"
                      min="1"
                      name="mul"
                      value={multiplier}
                      onChange={(e) => setMultiplier(e.target.value)}
                      placeholder="Quantity"
                      style={{
                        padding: "4px 5px",
                        borderRadius: 4,
                        borderWidth: 1,
                        borderColor: "#817e7e",
                        backgroundColor: "#ffffff",
                        width: "90%",
                      }}
                    />
                  ) : (
                    broker?.qty_mul
                  )}
                </td>
                {/* <td>{broker?.trade_on ? "Yes" : "No"}</td> */}
                <td>
                  <div className="flex items-center justify-center ml-[8px] mr-[8px]  bg-gray-100">
                    <span
                      style={{
                        fontSize: 10,
                        fontWeight: "700",
                        color: "#817E7E",
                        marginRight: 4,
                      }}
                    >
                      No
                    </span>
                    <button
                      className={`w-12 h-6 flex items-center rounded-full p-1 duration-300 ease-in-out`}
                      style={{
                        backgroundColor: broker?.trade_on ? "green" : "green  ",
                      }}
                      onClick={() => handleToggle(broker?.user_id)}
                    >
                      <div
                        style={{ backgroundColor: "white" }}
                        className={` w-4 h-4  rounded-full shadow-md transform duration-300 ease-in-out ${
                          broker?.trade_on ? "translate-x-6" : "translate-x-0"
                        }`}
                      />
                    </button>
                    <span
                      style={{
                        fontSize: 10,
                        fontWeight: "700",
                        color: "#817E7E",
                        marginLeft: 4,
                      }}
                    >
                      Yes
                    </span>
                  </div>
                </td>
                <td>{broker?.user_id__user_id__email}</td>
                <td>{broker?.user_id__user_id__username}</td>
                <td>
                  <button>
                    {!broker?.isEditEnabled ? (
                      <img
                        src={editIcon}
                        onClick={() => onClickEdit(index)}
                        alt="edit icon"
                        style={{ width: 18, height: 18, marginRight: 8 }}
                      />
                    ) : (
                      <img
                        src={correctIcon}
                        onClick={() =>
                          onUpdateChild(
                            broker?.user_id,
                            broker?.trade_on,
                            index
                          )
                        }
                        alt="update icon"
                        style={{ width: 18, height: 18, marginRight: 8 }}
                      />
                    )}
                  </button>
                  <button
                    onClick={() => {
                      onDeleteRow(broker?.user_id);
                    }}
                  >
                    <img
                      src={deleteIcon}
                      alt="delete icon"
                      style={{ width: 18, height: 18 }}
                    />
                  </button>
                </td>
              </tr>
            );
          })}
        </table>
      </div>

      <div className="pagination">
        <button onClick={handlePrev} disabled={currentPage === 1}>
          Previous
        </button>
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index + 1}
            onClick={() => handleClick(index + 1)}
            className={currentPage === index + 1 ? "active" : ""}
          >
            {index + 1}
          </button>
        ))}
        <button onClick={handleNext} disabled={currentPage === totalPages}>
          Next
        </button>
      </div>
    </>
  );
}

export default AccountList;
