import React, { useState, useContext, useEffect } from "react";
import "../signin/signinStyle.css";
import stlogin from "../../assets/icons/stlogin.png";
import stoxview from "../../assets/icons/stoxview.png";
import Google from "../../assets/icons/Google.png";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import MyContext from "../../context/MyContext";
import Loader from "../../components/loader/Loader";
import { useDispatch } from "react-redux";
import { setSessionID } from "../../redux/SessionSlice";
import { IoReload } from "react-icons/io5";

function OtpConfirmation() {
  const {
    loading,
    setLoading,
    setAuthToken,
    getDashboardCall,
    getPosition,
    callGetOptionChain,
    onResendOtp,
  } = useContext(MyContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [mobile, setMobile] = useState("");
  const dispatch = useDispatch();
  const [code, setCode] = useState(new Array(6).fill(""));
  const [timer, setTimer] = useState(30);
  const [resendDisabled, setResendDisabled] = useState(true);

  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state;

  //console.log("data : ",code);

  useEffect(() => {
    if (timer > 0) {
      const countdown = setTimeout(() => setTimer(timer - 1), 1000);
      return () => clearTimeout(countdown);
    } else {
      setResendDisabled(false);
    }
  }, [timer]);

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return;

    const updatedCode = [...code];
    updatedCode[index] = element.value;
    setCode(updatedCode);

    if (element.value && element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && code[index] === "") {
      if (e.target.previousSibling) {
        e.target.previousSibling.focus();
      }
    }
  };

  const handleResend = () => {
    data.mode === 1
      ? onResendOtp(1, {
          email: data.email,
          fname: data.fname,
          lname: data.lname,
          mobile_number: data.mobile,
        })
      : onResendOtp(0, {
          mobile_number: data.mobile,
        });
    setTimer(30);
    setResendDisabled(true);
    setCode(new Array(6).fill(""));
  };

  const otpConfirmation = async (e) => {
    e.preventDefault();
    setLoading(true);
    //here mode 1 equal to signup and otherwise login
    const body =
      data.mode === 1
        ? {
            email: data.email,
            mobile_number: data.mobile,
            otp: code.join(""),
          }
        : {
            mobile_number: data.mobile,
            otp: code.join(""),
          };

    console.log("verify otp body : ", body);

    await fetch("https://app.stoxviews.com/api/two-factor-authentication", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((value) => {
        console.log("verify mobile number otp value :", value);
        if (value.status) {
          setLoading(false);
          toast.success(`${value.message}`, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          dispatch(setSessionID(value.sessionID));
          localStorage.setItem("sessionID", JSON.stringify(value.sessionID));
          // localStorage.setItem("email", JSON.stringify(data.email));
          navigate("/dashboard", { replace: true });
          setCode(new Array(6).fill(""));
        } else {
          setLoading(false);
          toast.error(`${value.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setCode(new Array(6).fill(""));
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error("Signup Failed", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        console.log(err);
      });
    //window.location.href = "/";
  };

  return (
    <div className="flex flex-col items-center justify-center md:flex-row">
      {loading && <Loader />}
      <div className="mr-[147.76px] mt-[30px] mb-[30px]">
        <img
          src={stoxview}
          alt="logo icon"
          className="w-[129px] h-[30px] mb-[48px]"
        />
        <img
          src={stlogin}
          alt="login icon"
          className="w-[614.24px] h-[391.36px] mb-[48px]"
        />
        <span
          className="font-light"
          style={{ fontSize: 12, fontWeight: "400" }}
        >
          Copyright @ 2024 Stoxview. All Rights Reserved.
        </span>
      </div>

      <div
        className="w-[326px] cardStyle"
        style={{
          borderColor: "#E6E6E6",
          padding: 32,
          borderRadius: 6,
          borderWidth: 1,
        }}
      >
        <form onSubmit={otpConfirmation}>
          <h1 className="welcomeBack mb-[16px]  text-center">Welcome Back</h1>

          {code.map((value, index) => (
            <input
              key={index}
              type="text"
              maxLength="1"
              value={value}
              onChange={(e) => handleChange(e.target, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              onFocus={(e) => e.target.select()}
              style={{
                width: "40px",
                height: "40px",
                fontSize: "24px",
                textAlign: "center",
                border: "2px solid #ccc",
                borderRadius: "5px",
                marginRight: index !== 0 || index !== code.length - 1 ? 3 : 0,
              }}
            />
          ))}

          <div
            className="mb-[16px] mt-[16px] h-[40px] w-[262px] flex items-center justify-center text-center"
            style={{ backgroundColor: "#39A245", borderRadius: 4 }}
          >
            <button
              style={{ fontSize: 12, color: "#FFFFFF" }}
              // onClick={() => {
              //   otpConfirmation();
              // }}
              type="submit"
            >
              confirm
              {/* Login to Stoxview */}
            </button>
          </div>
          <div
            className="flex items-center"
            style={{
              color: resendDisabled ? "#fa7093" : "grey",
              cursor: resendDisabled ? "not-allowed" : "pointer",
              background: "none",
              border: "none",
            }}
          >
            <span className="mr-[5px] " style={{ fontSize: 12 }}>
              Resend otp {timer !== 0 && "in"} {timer !== 0 && timer}{" "}
              {timer !== 0 && "sec"}
            </span>
            <button onClick={handleResend} disabled={resendDisabled}>
              {timer === 0 && <IoReload />}
            </button>
          </div>
        </form>

        {/* <div className="text-center">
          <button className="font-light" style={{ fontSize: 12 }}>
            Forgot Password?
          </button>
        </div> */}

        {/* <div
          className="w-[262px] h-[0px] mb-[16px] mt-[16px]"
          style={{ borderColor: "#D9D9D9", borderWidth: 1 }}
        ></div> */}

        {/* <div className=" text-center">
          <span style={{ fontSize: 12 }}>Don't have an account?</span>
          <Link
            to="/signup"
            style={{ fontSize: 12, marginLeft: 4, textDecoration: "underline" }}
          >
            Create Account
          </Link>
        </div> */}
      </div>
    </div>
  );
}

export default OtpConfirmation;
